﻿import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { IValidationMessage } from 'shared/models/forms/IValidationMessage';


@Injectable()
export class FormValidationService {
    private dead: Subject<void> = new Subject<void>();

    private _lastMessage: Subject<IValidationMessage> = new Subject<IValidationMessage>();

    get lastMessage() {
        return this._lastMessage;
    }

    public setMessage(message: IValidationMessage) {
        this._lastMessage.next(message);
    }


    public resetMessage() {
        this._lastMessage.next(null);
    }


    public destroy(): void {
        this.dead.next();
        this.dead.complete();
    }
}
