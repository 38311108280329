import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from "@angular/router";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { SharedModule } from "shared/shared.module";
import { TranslateLanguageLoader, AppTranslationService } from 'shared/services/app-translation.service';
import { SharedSingletonsModule } from "shared/shared-singletons.module";
import { AuthGuardService } from "shared/routeGuards/auth-guard.service";
import { AuthService } from "shared/services/auth.service";

import { AppRootComponent } from "./app-root.component";
import { RootApiService } from "./root-api.service";



const routes: Routes = [
    {
        path: "",
        redirectTo: "app", pathMatch: "full"
    },
    {
        path: 'app',
        loadChildren: 'app/app.module#AppModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'auth',
        loadChildren: 'auth/auth.module#AuthModule'
    },
    { path: "**", redirectTo: "app", pathMatch: "full" }
];



@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        SharedSingletonsModule.forRoot(),
        RouterModule.forRoot(routes),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        })
    ],
    declarations: [
        AppRootComponent
    ],
    providers: [
        { provide: 'BASE_URL', useFactory: () => document.getElementsByTagName('base')[0].href },
        RootApiService
    ],
    bootstrap: [AppRootComponent]
})
export class RootModule {
    constructor(
        auth: AuthService,
        api: RootApiService,
        appTranslate: AppTranslationService
    ) {
        if (auth.isLoggedIn && !auth.isAdmin) {
            api.profile().subscribe(
                profile => appTranslate.changeLanguage(profile.language == 1 ? 'en' : 'ru'),
                error => console.error(error)
            )
        }
    }
}