﻿import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'form-submit',
    templateUrl: './form-submit.component.html',
    styleUrls: ['./form-submit.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSubmitComponent
{
    @Input()
    backUrl: string | any[];

    @Input()
    formIsValid: boolean = false;

    constructor(
        protected router: Router
    ) {
    }

    public back() {
        if (this.backUrl && this.backUrl.length > 0) {
            this.router.navigate([this.backUrl]);
        }
    }
}
