﻿import { Component, Input, EventEmitter, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
    selector: 'checkbox',
    templateUrl: 'checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true
        }
    ]
})
export class CheckboxComponent implements ControlValueAccessor {
    constructor() { }

    private _checked: boolean = false;
    private _disabled: boolean = false;
    private _innerLabel: string = '';
    private _outerLabel: string = '';

    propagateChange = (_: any) => { };
    propagateTouched = (_: any) => { };

    @Output() modelChange = new EventEmitter<boolean>();

    @Input()
    public set model(checked: boolean) { this._checked = checked || false; }
    public get checked() { return this._checked; }

    @Input()
    public set disabled(val: boolean) { this._disabled = val || false }
    public get disabled() { return this._disabled; }

    @Input()
    public set innerLabel(val: string) { this._innerLabel = val || ''; }
    public get innerLabel() { return this._innerLabel; }
    public get showInnerLabel() { return this._innerLabel && this._innerLabel.length > 0; }

    @Input()
    public set outerLabel(val: string) { this._outerLabel = val || ''; }
    public get outerLabel() { return this._outerLabel; }
    public get showOuterLabel() { return this._outerLabel && this._outerLabel.length > 0; }


    private emitValue() {
        this.propagateChange(this._checked);
        this.modelChange.emit(this._checked);
    }

    public toggle(): void {
        if (this.disabled)
            return;

        this._checked = !this._checked;
        this.emitValue();
    }


    //#region ControlValueAccessor realization

    writeValue(obj: any): void {
        if (obj != undefined && !!obj === obj) {
            this._checked = obj;
            this.emitValue();
        }
    }


    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    //# endregion
}