﻿import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';


@Injectable({
    providedIn: 'root',
})
export class AppStateService
{
    // TODO
    // in production use Base64 - not clear JSON

    private readonly APP_STATE_PREFIX = 'Iage.Bidder';

    constructor(
        private auth: AuthService
    ) {
    }

    private personalizeStorageKey(key: string): string {
        return `${this.APP_STATE_PREFIX}:${this.auth.authData.id}:${key}`;
    }

    public getState<T>(key: string): T {
        const storedValue = localStorage.getItem(this.personalizeStorageKey(key));
        try {
            return JSON.parse(storedValue) as T;
        } catch (err) {
            console.error(`Erorr while parsing stored object [${key}]: `, storedValue);
            return null;
        }
    }

    public setState<T>(key: string, value: T) {
        localStorage.setItem(this.personalizeStorageKey(key), JSON.stringify(value));
    }

    public clearState(key: string) {
        localStorage.removeItem(this.personalizeStorageKey(key));
    }

    public clearStorage() {
        localStorage.clear();
    }
}