﻿import {
    Component, OnChanges, Input, Output, EventEmitter, ElementRef,
    IterableDiffers, ViewChild, AfterViewInit, OnDestroy, forwardRef, ChangeDetectionStrategy, ChangeDetectorRef,
} from '@angular/core';

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { fromEvent, Observable, Subscription } from 'rxjs';

import { IDropdownItem } from 'shared/models/ui/IDropDownItem';
import { UiService } from 'shared/services/ui.service';
import { EKeyboardKeys } from 'shared/enums/EKeyboardKeys';


export interface IDropdownSearchComponentState {
    opened: boolean;
    model: string;
}

export interface IDropdownSearchSelectableDropdownItem
    extends IDropdownItem {
    selected?: boolean;
}


@Component({
    selector: 'dropdown',
    templateUrl: 'dropdown.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent
    implements OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor {

    private iterableDiffer: any;
    public selected = false;
    public opened = false;
    public displayName = '';
    public uniqueId: number = Math.floor(Math.random() * 100000);
    public filteredListItems: IDropdownSearchSelectableDropdownItem[] = [];
    private searchInputStream: Observable<KeyboardEvent>;
    private searchInputStreamSubscription: Subscription;

    private readonly DEFAULT_LIST_HEIGHT: number = 200;
    private readonly LIST_ITEM_HEIGHT: number = 42; // TODO calculate this


    //#region inputs and outputs

    @Input()
    public searchable = true;

    @Input()
    public size?: string = 'medium';

    @Input()
    public nullable?: boolean = true;

    @Input()
    public scrollable: boolean = true;

    @Input()
    public placeholder: string = '';

    @Input()
    public readonly: boolean = false;

    @Input()
    public listItems: IDropdownItem[] = [];

    @Input()
    public resetAfterSelect: boolean = false;

    @Input()
    public hideDisabledItems: boolean = false;

    @Input()
    public model: string;

    @Input()
    public logging: boolean = false;

    @Output()
    public modelChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public stateChange: EventEmitter<IDropdownSearchComponentState> = new EventEmitter<IDropdownSearchComponentState>();

    @Input()
    public set openDirection(value) {
        this._openDirection = value;
        this.setupToggleDirection();
    }
    private _openDirection: 'top' | 'bottom' | 'auto' = 'auto';
    public get openDirection() { return this._openDirection }

    private openDirectionSubscription: Subscription;

    //#endregion


    @ViewChild('currentValue')
    public currentValue: ElementRef;

    @ViewChild('topLevelElement')
    public topLevelElement: ElementRef;

    @ViewChild('searchInput')
    public searchInput: ElementRef;

    @ViewChild('list')
    public listElement: ElementRef;

    public get noLabel() { return !this.placeholder || this.placeholder.length == 0 }

    public get isDisabled(): boolean { return this.readonly || !this.listItems || this.listItems.length === 0 }

    constructor(
        private ui: UiService,
        private cdr: ChangeDetectorRef,
        private _element: ElementRef,
        private _iterableDiffers: IterableDiffers,
    ) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);

        this.searchInputStreamSubscription = this.ui.documentClickEvent.subscribe((event) => {
            const target = event.target as HTMLElement;

            this.log('[' + this.uniqueId + '] DOCUMENT CLICK: ', target);

            if (target.hasAttribute('data-dropdown-id')) {
                if (target.getAttribute('data-dropdown-id') !== this.uniqueId.toString()) {
                    this.log('closed by another dropdown');
                    this.setOpenState(false);
                }
            } else {
                this.log('closed by outside click');
                this.setOpenState(false);
            }
        });
    }


    //#region lifecycle hooks

    ngOnChanges(changes: any): void {
        
        const listChange = this.iterableDiffer.diff(this.listItems);
        this.log('ngOnChanges: listChange [' + this.uniqueId + ']: ', listChange);

        if (listChange) {
            this.filteredListItems = this.listItems as IDropdownSearchSelectableDropdownItem[];
        }

        if (changes.placeholder) {
            this.setDefaultDisplayName();
        }

        this.setModelValue();
    }


    ngAfterViewInit(): void {
        this.searchInputStream = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'keyup');

        this.searchInputStream.subscribe((event: KeyboardEvent) => {
            if (!this.opened) {
                return;
            }

            event.stopImmediatePropagation();

            switch (event.key) {
                case EKeyboardKeys.ArrowUp:
                case EKeyboardKeys.ArrowDown:
                    this.navigateByArrows(event.key);
                    break;
                case EKeyboardKeys.Enter:
                    this.selectItemByEnter();
                    break;
                case EKeyboardKeys.Escape:
                    this.closeListByEscape();
                    break;
                default:
                    this.findAndResetArrowSelectedItem();
                    this.filterItems((event.target as HTMLInputElement).value);
            }
        });

        const rootElement = this._element.nativeElement as HTMLElement;
        rootElement.addEventListener('focus', () => rootElement.classList.add('focused'));
        rootElement.addEventListener('blur', () => rootElement.classList.remove('focused'));

        rootElement.addEventListener('keypress', (event: KeyboardEvent) => {
            if (!this.opened && event.key === EKeyboardKeys.Enter) {
                this.setOpenState(true);
            }
        });

        if (!this.openDirection || this.openDirection == 'auto') {
            this.setupToggleDirection();
            this.calculateCurrentDirection()
        }
    }


    ngOnDestroy(): void {
        this.searchInputStreamSubscription.unsubscribe();
        if (this.openDirectionSubscription) {
            this.openDirectionSubscription.unsubscribe();
        }
    }

    //#endregion


    //#region ControlValueAccessor realization

    propagateChange = (_: any) => { };
    propagateTouched = (_: any) => { };

    public writeValue(obj: any): void {
        this.log('[' + this.uniqueId + '] writeValue:', obj);

        if (obj === null) {
            this.model = null;
            this.setDefaultDisplayName();
        } else {
            this.model = obj.toString();
            this.setModelValue();
            this.modelChange.emit(this.model);
        }

        this.cdr.markForCheck();
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.readonly = true;
    }

    //#endregion


    //#region private methods

    private log(...log: any[]) {
        if (!this.logging)
            return;

        console.log(...log);
    }


    private setDefaultDisplayName(): void {
        this.displayName = this.placeholder
            ? this.placeholder
            : 'Select value';
    }


    private setModelValue(): void {
        this.log('[' + this.uniqueId + ']: setmodelValue: ', this.model);
        this.log('this.filteredListItems: ', this.filteredListItems);

        if (this.model !== undefined && this.model != null) {
            this.selected = false;
            for (let i in this.filteredListItems) {
                if (this.filteredListItems[i].id.toString() === this.model.toString()) {
                    this.displayName = this.filteredListItems[i].name;
                    this.selected = true;
                }
            }
        } else {
            this.setDefaultDisplayName();
            this.selected = false;
        }
    }


    private emitModelValue(value: string = null): void {
        const emittedValue = value != null ? value : this.model;
        this.modelChange.emit(emittedValue);
        this.propagateChange(emittedValue);
    }


    private setOpenState(isOpened: boolean): void {
        if (this.isDisabled) {
            return;
        }

        this.log('setOpenState: ', isOpened);

        this.opened = isOpened;

        this.stateChange.emit({ opened: this.opened, model: this.model });

        this.findAndResetArrowSelectedItem();

        if (isOpened) {
            setTimeout(() => {
                (this.searchInput.nativeElement as HTMLInputElement).value = '';
                this.searchInput.nativeElement.focus();
            });
        } else {
            this.filterItems('');
        }

        this.cdr.detectChanges();
    }


    private filterItems(key: string): void {
        // todo: debounce if many items

        key = key.toLowerCase();

        this.filteredListItems = this.listItems
            .filter(item => item.name.toLowerCase().indexOf(key) > -1)
            .map(item => ({
                id: item.id,
                name: item.name,
                disabled: item.disabled,
                selected: false,
            } as IDropdownSearchSelectableDropdownItem));

        this.cdr.markForCheck();
    }


    private navigateByArrows(key: string): void {
        if (this.filteredListItems.length === 0) {
            return;
        }

        const currentSelectedIndex = this.filteredListItems.findIndex((item) => item.selected === true);

        if (currentSelectedIndex < 0) {
            this.filteredListItems[0].selected = true;
        } else {
            if (
                (key === EKeyboardKeys.ArrowUp && currentSelectedIndex === 0) ||
                (key === EKeyboardKeys.ArrowDown && currentSelectedIndex === this.filteredListItems.length - 1)
            ) {
                return;
            }

            this.filteredListItems[currentSelectedIndex].selected = false;
            const delta = (key === EKeyboardKeys.ArrowDown ? 1 : -1);
            this.filteredListItems[currentSelectedIndex + delta].selected = true;

            if (currentSelectedIndex > 1 && currentSelectedIndex < this.filteredListItems.length - 2) {
                const listElement = this.listElement.nativeElement as HTMLElement;
                listElement.scrollTop = listElement.scrollTop + delta * this.LIST_ITEM_HEIGHT;
            }
        }

        this.cdr.detectChanges();
    }


    private findAndResetArrowSelectedItem(scrollToTop: boolean = true): number {
        if (scrollToTop) {
            let listElement = this.listElement.nativeElement as HTMLElement;
            listElement.scrollTop = 0;
        }

        const currentSelectedIndex = this.filteredListItems.findIndex((item) => item.selected === true);
        if (currentSelectedIndex > -1) {
            this.filteredListItems[currentSelectedIndex].selected = false;
        }

        return currentSelectedIndex;
    }


    private selectItemByEnter(): void {
        const currentSelectedIndex = this.findAndResetArrowSelectedItem();
        if (currentSelectedIndex > -1) {
            this.model = this.filteredListItems[currentSelectedIndex].id;
            this.setModelValue();
            this.emitModelValue();
            this.setOpenState(false);
        }
    }


    private closeListByEscape(): void {
        this.findAndResetArrowSelectedItem();
        this.setOpenState(false);
        this.setModelValue();
    }


    private calculateCurrentDirection() {
        const elementBoundingRect = (this.topLevelElement.nativeElement as HTMLElement).getBoundingClientRect();

        const listHeight = this.opened
            ? (this.listElement.nativeElement as HTMLElement).getBoundingClientRect().height
            : this.DEFAULT_LIST_HEIGHT;

        const delta = window.innerHeight - elementBoundingRect.top - elementBoundingRect.height - listHeight;
        const newOpenDirection = delta > 0 ? 'bottom' : 'top';

        if (this._openDirection != newOpenDirection) {
            this._openDirection = newOpenDirection;
            this.cdr.detectChanges();
        }
    }


    private setupToggleDirection() {
        if (this.openDirection == 'auto') {
            this.openDirectionSubscription = fromEvent(window, 'resize').subscribe(
                () => this.calculateCurrentDirection()
            );
        } else {
            if (this.openDirectionSubscription) {
                this.openDirectionSubscription.unsubscribe();
            }
        }
    }

    //#endregion


    //#region public methods

    public itemClick(index: number, event: any): void {
        if (!this.isDisabled) {
            const newValue: string = this.filteredListItems[index].id;
            this.emitModelValue(newValue);

            if (this.resetAfterSelect) {
                this.model = null;
                this.setDefaultDisplayName();
                this.selected = false;
            } else {
                this.model = newValue;
                this.displayName = this.filteredListItems[index].name;
                this.selected = true;
            }

            this.setOpenState(false);
            this.cdr.detectChanges();
        }
    }


    public itemHover(i: number, event: MouseEvent): void {
        this.findAndResetArrowSelectedItem(false);

        const item = this.filteredListItems[i];
        if (item) {
            item.selected = true;
        }
    }


    public toggleList(event: Event): void {
        this.setOpenState(!this.opened);
    }


    public open(): void {
        this.setOpenState(true);
    }


    public close(): void {
        this.setOpenState(false);
    }


    public clearValue(emit: boolean = true): void {
        this.model = null;
        this.setModelValue();
        this.filteredListItems = this.listItems;
        setTimeout(() => this.searchInput.nativeElement.value = '');

        if (emit) {
            this.emitModelValue();
        }
    }

    //#endregion
}
