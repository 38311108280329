import { Component } from '@angular/core';
import { fadeInOut } from 'app/services/animations';

@Component({
    selector: 'not-found-page',
    templateUrl: './not-found-page.component.html',
    animations: [fadeInOut]
})
export class NotFoundPageComponent {
}
