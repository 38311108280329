﻿import {
    Component, forwardRef, Optional, Host, SkipSelf, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef,
    ElementRef, AfterViewInit, ViewChild, EventEmitter, Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';

import { FormInputComponent } from '../formInputComponent';


@Component({
    selector: 'input-text',
    templateUrl: 'input-text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextComponent),
            multi: true,
        },
    ],
})

export class InputTextComponent
    extends FormInputComponent<string>
    implements OnInit, AfterViewInit
{
    @Input()
    public multiline?: boolean = false;

    @Input()
    public symbolsCount?: number = null;

    @Input()
    public autocomplete: string = 'off';

    @Input()
    public id: string = '';

    @ViewChild('textInput')
    public textInput: ElementRef;

    @Output()
    public onFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public onBlur: EventEmitter<boolean> = new EventEmitter<boolean>();

    public uniqueId: number = Math.floor(Math.random() * 100000);

    private _focused: boolean = false;
    public get focused() { return this._focused }

    public hidePasswordStars: boolean = false;

    public get showTheEye() { return this.htmlType && this.htmlType.toLowerCase() == 'password' }

    public get showLengthCounter() { return this.htmlType && this.htmlType == 'text' && this.symbolsCount && this.symbolsCount > 0 }

    private _errors: string[] = [];
    public get errors(): string[] {
        return this._errors;
    }

    constructor(
        @Optional() @Host() @SkipSelf()
        protected controlContainer: ControlContainer,
        protected cdr: ChangeDetectorRef
    ) {
        super(controlContainer, cdr);
    }

    private setFocusState(state: boolean) {
        this._focused = state;

        if (state) {
            this.onFocus.emit(true);
        } else {
            this.onBlur.emit(false);
        }

        this.cdr.markForCheck();
    }

    ngAfterViewInit() {
        this.textInput.nativeElement.addEventListener('focus', () => { this.setFocusState(true) });
        this.textInput.nativeElement.addEventListener('blur', () => { this.setFocusState(false) });
    }
}
