﻿import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";

import * as cookie from 'js-cookie';

import { IAuthData } from 'shared/models/auth/IAuthData';
import { UiService } from 'shared/services/ui.service';
import { MessageBusService } from 'shared/services/messageBus/message-bus.service';
import { EventLoggedIn } from 'shared/services/messageBus/events/EventLoggedIn';
import { EventLoggedOut } from 'shared/services/messageBus/events/EventLoggedOut';


@Injectable({
    providedIn: 'root',
})
export class AuthService
{
    private readonly AUTH_COOKIE_LIFETIME: number = 6*30*24*60*60*1000;
    private readonly STORAGE_KEY = 'IBAUTH';
    private jwtHelper: JwtHelperService;

    private _token: string;
    public get token() { return this._token }

    private _authData: IAuthData = null;
    public get authData() { return this._authData }

    public get isAdmin() { return this._authData && this._authData.role.toLowerCase() === 'administrator' }
    public get isAgency() { return this._authData && this._authData.role.toLowerCase() === 'agency' }
    public get isAdvertiser() { return this._authData && this._authData.role.toLowerCase() === 'advertiser' }

    public get isLoggedIn(): boolean {
        if (this._token && !this._authData) {
            this.decodeAndSaveToken(this._token);
        }

        return this._authData && !isNaN(this.authData.id) && this.authData.role && this.authData.role.length > 0;
    }

    constructor(
        protected router: Router,
        protected ui: UiService,
        protected bus: MessageBusService
    ) {
        this.jwtHelper = new JwtHelperService();
        this._token = this.getAuthCookie();
    }


    public login(token: string) {
        this._token = token;
        this.decodeAndSaveToken(token);
        this.setAuthCookie(token);
        this.router.navigate(['/app']);
        this.bus.emit(new EventLoggedIn(this.authData.role));
    }

    public logout() {
        this.deleteAuthCookie();
        this._authData = null;
        this.router.navigate(['/auth']);
        this.bus.emit(new EventLoggedOut(null));
    }

    public decodeAndSaveToken(token?: string) {
        const decodedToken: any = this.jwtHelper.decodeToken(token);
        this._token = token;
        this._authData = {
            aud: decodedToken.aud,
            exp: new Date(decodedToken.exp * 1000),
            id: parseInt(decodedToken.id, 10),
            iss: decodedToken.iss,
            nbf: parseInt(decodedToken.nbf, 10),
            profile: null,
            role: decodedToken.role
        };
    }

    private setAuthCookie(token: string) {
        cookie.set(this.STORAGE_KEY, token, {
            expires: new Date(Date.now() + this.AUTH_COOKIE_LIFETIME),
            path: '/',
            sameSite: "strict"
        })
    }

    private getAuthCookie(): string {
        return cookie.get(this.STORAGE_KEY);
    }

    private deleteAuthCookie() {
        cookie.remove(this.STORAGE_KEY, { path: '/' });
    }
}