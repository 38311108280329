﻿import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injector, Inject, Injectable } from '@angular/core';

import { IApiDto } from 'shared/models/api/IApiDto';
import { IApiFieldError } from 'shared/models/api/IApiFieldError';
import { IValidationMessage } from 'shared/models/forms/IValidationMessage';
import { IDictionaryItem } from 'shared/models/global/IDictionaryItem';
import { IApiListResponse } from 'shared/models/api/IApiListResponse';
import { IApiListRequest } from 'shared/models/api/IApiListRequest';

import { FormValidationService } from '../form-validation.service';
import { AppTranslationService } from '../app-translation.service';
import { IApiService } from './IApiService';


export class BaseApiService
    implements IApiService
{
    private readonly _baseUrl: string = 'https://dev.uiapi.iae.one/v1/';
    public get baseUrl() { return this._baseUrl }

    private _headers: HttpHeaders;
    protected get headers() { return this._headers }

    private _multipartHeaders: HttpHeaders;
    protected get multipartHeaders() { return this._multipartHeaders }

    protected http: HttpClient;

    protected appTranslation: AppTranslationService;

    protected validation: FormValidationService;

    constructor(injector: Injector)
    {
        this.http = injector.get(HttpClient);
        this.appTranslation = injector.get(AppTranslationService);
        this.validation = injector.get(FormValidationService);

        this._headers = new HttpHeaders().set("Content-Type", "application/json; charset=utf-8");
        this._multipartHeaders = new HttpHeaders();
    }
    

    public static readonly API_URL_SEGMENTS = {
        PROFILE: 'profiles',
        USERS: 'users',
        ADCAMPAIGNS: 'campaigns',
        ADGROUPS: 'groups',
        TARGETINGFILES: {
            CRUD: 'targetingfiles',
            VALIDATE: 'targetingfiles/validate',
        },
        AGENCIES: 'agencies',
        ADVERTISERS: 'vendors',
        SSP: 'ssps',
        CREATIVES: {
            LIST: 'creatives',
            BANNER_IMAGES: 'creatives/banners/images',
            BANNER_HTML: 'creatives/banners/htmls',
            VIDEO_LINEAR_INLINE: 'creatives/videos/inlines',
            VIDEO_LINEAR_WRAPPER: 'creatives/videos/wrappers',
            VIDEO_LINEAR_INTERACTIVE: 'creatives/videos/vpaids',
            VIDEO_NONLINEAR: 'creatives/videos/nonlinears',
            NATIVE_MOBILE: 'creatives/natives/mobiles'
        },
        DICTIONARIES: {
            GEOS: 'geos',
            GEOLOCATIONS: '',
            COUNTRIES: 'countries',
            TIMEZONES: 'countries/{id}/timezones',
            CATEGORIES: 'categories',
            DEVICES: 'devices',
            MANUFACTURERS: 'manufacturers',
            OPERATINGSYSTEMS: 'operatingsystems',
            CARRIERS: 'carriers',
            BROWSERS: 'browsers',
            LANGUAGES: 'languages',
            SSPS: 'ssps/list',
            POSITIONS: 'positions',
            DOMAINS: '',
            APPLICATIONS: '',
            IPS: ''
        },
        VALIDATORS: 'validate',
        REPORTS: {
            LIST: 'reports',
            DIMENSIONS: 'reports/filters/statcolumns/?type=1',
            METRICS: 'reports/filters/statcolumns/?type=2',
            TIMEZONES: 'reports/filters/timezoneoffsets',
            FILTERSSEARCH: 'reports/filters',
            FILTERS: {
                AGENCIES: 'agencies',
                ADVERTISERS: 'vendors',
                ADCAMPAIGNS: 'campaigns',
                ADGROUPS: 'groups',
                ADS: 'creatives',
                SSPS: 'ssps',
                COUNTRIES: 'countries',
                BROWSERS: 'browsers',
                DEVICES: 'devices',
                OPERATINGSYSTEMS: 'operatingsystems',
                MANUFACTURERS: 'manufacturers',
                CATEGORIES: 'categories',
                CARRIERS: 'carriers'
            },
            DASHBOARD: 'stats/dashboard',
            REPORT: 'stats',
            CHART: 'stats/chart',
        },
        BILLING: ''
    }


    public setAuthToken(token: string) {
        this._headers = this._headers.set("Authorization", `Bearer ${token}`);
        this._multipartHeaders = this._multipartHeaders.set("Authorization", `Bearer ${token}`);
    }

    protected cleanNullFields(source):any {
        const cleaned = JSON.parse(JSON.stringify(source));
        let keysForRemove: string[] = [];

        Object.getOwnPropertyNames(cleaned).forEach(p => {
            if (cleaned[p] == null) {
                keysForRemove.push(p);
            }
        });

        keysForRemove.forEach(key => {
            delete cleaned[key];
        });

        return cleaned;
    }

    protected handleError(error: HttpErrorResponse, useGlobalMessages = true) {
        let errorMessage: IValidationMessage;

        if (error.status == 400) {
            errorMessage = {
                apiResponse: {
                    errorMessage: error.message,
                    errorDetails: <IApiFieldError[]>[],
                    isSuccessful: false
                }
            };

            Object.keys(error.error).forEach(key => {
                errorMessage.apiResponse.errorDetails.push({
                    errorDescriptor: key,
                    errorMessage: error.error[key]
                });
            });
        }
        else if (error.status == 500) {
            errorMessage = {
                apiResponse: {
                    errorDetails: [],
                    errorMessage: 'Server error: ' + error.message,
                    isSuccessful: false
                }
            };
        }
        else {
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
        }

        if (useGlobalMessages) {
            this.validation.setMessage(errorMessage);
        }

        return throwError(error);
    };


    //#region BASE METHODS


    public get<T>(url: string, useGlobalMessages = true): Observable<T> {
        return this.http
            .get<T>(this._baseUrl + url, { headers: this.headers })
            .pipe(catchError(error => this.handleError(error, useGlobalMessages)));
    }


    public post<RequestType, ResponseType>(url: string, request?: RequestType, useGlobalMessages = true): Observable<ResponseType> {
        return this.http
            .post<ResponseType>(this._baseUrl + url, request ? JSON.stringify(this.cleanNullFields(request)) : '', { headers: this.headers })
            .pipe(catchError(error => this.handleError(error)));
    }


    public create<RequestType>(url: string, request?: RequestType, useGlobalMessages = true): Observable<IApiDto> {
        return this.post<RequestType, IApiDto>(url, request);
    }


    public createMultipart(url: string, formData: FormData, useGlobalMessages = true): Observable<IApiDto> {
        return this.http
            .post<IApiDto>(this._baseUrl + url, formData, { headers: this.multipartHeaders })
            .pipe(catchError(error => this.handleError(error)));
    }


    public update<RequestType>(url: string, request?: RequestType, useGlobalMessages = true): Observable<any> {
        return this.http
            .put(this._baseUrl + url, request ? JSON.stringify(this.cleanNullFields(request)) : '', { headers: this.headers })
            .pipe(catchError(error => this.handleError(error)));
    }


    protected getPagedList<T>(url: string, request?: IApiListRequest): Observable<IApiListResponse<T>> {
        let cleanedUrl = url;

        const hasEndedSlash: boolean = url[url.length - 1] == '/';

        if (hasEndedSlash) {
            cleanedUrl = url.slice(0, url.length - 1);
        }

        if (request) {
            cleanedUrl =
                `${cleanedUrl}/?pageSize=${request.paging.pageSize}&page=${request.paging.currentPage}` +
                `&sortBy=${request.order.sortBy}&sortDesc=${request.order.sortDesc}`;

            if (request.additionalParams) {
                Object.keys(request.additionalParams).forEach(key =>
                    cleanedUrl += `&${key}=${request.additionalParams[key]}`
                );
            }
        }

        if (request && request.searchTerm && request.searchTerm.length > 0) {
            cleanedUrl += '&searchTerm=' + encodeURI(request.searchTerm);
        }

        return this.get<IApiListResponse<T>>(cleanedUrl);
    }


    public setItemStatus(urlSegment: string, id: number, status: number): Observable<any> {
        const body = {
            id: id,
            status: status
        };
        return this.update<any>(`${urlSegment}/${id}/status`, body);
    }

    //#endregion


    //#region DICTIONARIES

    public getDictionary(urlSegment: string, parentId?: number): Observable<IDictionaryItem[]> {
        const language = this.appTranslation.getCurrentLanguage() == 'en' ? 1 : 2;
        const url = parentId
            ? `${urlSegment}/${parentId}?language=${language}`
            : `${urlSegment}?language=${language}`;

        return this.get<IDictionaryItem[]>(url);
    }

    public searchInDictionary(urlSegment: string, term: string): Observable<IDictionaryItem[]> {
        const url = `${urlSegment}/search/?searchTerm=${encodeURI(term)}`;
        return this.get<IDictionaryItem[]>(url);
    }


    public timezones(countryId: string): Observable<IDictionaryItem[]> {
        if (!countryId || countryId.length == 0) {
            throw new Error('Cant get timezones for empty countryId');
        }

        const language = this.appTranslation.getCurrentLanguage() == 'en' ? 1 : 2;
        const url = BaseApiService.API_URL_SEGMENTS.DICTIONARIES.TIMEZONES.replace('{id}', countryId) + `?language=${language}`;
        return this.get<IDictionaryItem[]>(url);
    }

    //#endregion
}
