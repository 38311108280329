﻿import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AuthService } from "shared/services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService
    implements CanActivate
{
    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean
    {
        if (this.auth.isLoggedIn)
            return true;

        this.router.navigateByUrl('/auth');
        return false;
    }
}