﻿import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BaseApiService } from 'shared/services/base/base-api.service';
import { AuthService } from 'shared/services/auth.service';
import { IProfileReadDto } from 'app/models/profile/IProfileReadDto';

@Injectable()
export class RootApiService
    extends BaseApiService
{
    constructor(injector: Injector, auth: AuthService) {
        super(injector);
        this.setAuthToken(auth.token);
    }
    
    public profile(): Observable<IProfileReadDto> {
        return this.get<IProfileReadDto>(BaseApiService.API_URL_SEGMENTS.PROFILE);
    }
}
