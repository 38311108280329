﻿import { Component, Input } from '@angular/core';

@Component({
    selector: 'collapsable-area',
    templateUrl: './collapsable-area.component.html'
})
export class CollapsableAreaComponent
{
    @Input()
    public title: string = "";

    private _collapsed: boolean = true;

    @Input()
    public set collapsed(value: boolean) { this._collapsed = value }

    public get collapsed() { return this._collapsed }

    constructor() { }

    public toggle() {
        this._collapsed = !this._collapsed;
    }
}
