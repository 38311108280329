/**
 * Configuration service
 */
import { Injectable } from '@angular/core';

@Injectable()
/**
 * Configuration service
 */
export class ConfigService {
    /**
     * Api URL
     */
    public static readonly API_URL: string = 'http://34.211.153.185/';

    /**
     * Date format to UI datepickers component
     */
    public static readonly UI_DATE_FORMAT: string = 'DD-MM-YYYY';

    /**
     * Date format to store in DB
     */
    public static readonly DB_DATE_FORMAT: string = 'YYYY-MM-DD';

    /**
     * Date format in AdServer
     */
    public static readonly ADSERVER_DATE_FORMAT: string = 'YYYY-MM-DD';

    /**
     * Number pattern for comission validation
     * */
    public static readonly COMMISSION_VALIDATION_REGEXP = new RegExp(/^(\d+)$|^(\d+\.\d{1,2})$/g);

    /**
     * Map engine used for geo targeting
     * */
    public static readonly MAP_ENGINE: 'google' | 'here' | 'leaflet' = 'leaflet';
}