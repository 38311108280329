﻿import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';


@Component({
    selector: 'activity-changer',
    templateUrl: 'activity-changer.component.html',
    providers: []
})
export class ActivityChangerComponent
    implements OnChanges
{
    constructor() { }

    private _model: boolean = false;

    @Input()
    set model(model: boolean) {
        this._model = model;
    }

    @Input() public disabled: boolean = false;

    get model() {
        return this._model;
    }

    @Output() modelChange = new EventEmitter<boolean>();

    ngOnChanges(changes) {
        if (changes.model && changes.model.previousValue !== undefined) {
            this._model = changes.model.currentValue as boolean;
        }
    }

    public toggle(): void {
        if (!this.disabled) {
            this._model = !this._model;
            this.modelChange.emit(this._model);
        }
    }
}