﻿import { Component, OnInit, Input, EventEmitter, Output, forwardRef, HostListener, ElementRef } from '@angular/core';

import * as moment from 'moment';
//import * as _ from 'underscore';

import { IMyDpOptions, IMyDate, IMyMarkedDates } from 'mydatepicker';
import { ConfigService } from 'app/services/config.service';
import { UiService } from 'shared/services/ui.service';
import { IDateInterval } from 'shared/models/ui/IDateInterval';


export interface IMyDateRange {
    start: { date: IMyDate },
    end: { date: IMyDate }
}


@Component({
    selector: 'date-range-picker',
    templateUrl: 'date-range-picker.component.html'
})
export class DateRangePickerComponent
    implements OnInit
{
    protected readonly MOMENT_DATE_FORMAT: string = 'M/D/YYYY';
    protected readonly MY_DATE_FORMATE: string = 'M/D/YYYY';

    public datePickerVisible = false;

    @Input() public dateFormat: string;

    public startDatePickerOptions: IMyDpOptions = {
        inline: true,
        showInputField: false,
        dateFormat: this.MY_DATE_FORMATE,
        satHighlight: true
    };

    public endDatePickerOptions: IMyDpOptions = {
        inline: true,
        showInputField: false,
        dateFormat: this.MY_DATE_FORMATE,
        satHighlight: true
    };

    @Input() public intervals: IDateInterval[] = [];

    @Input() public startDate: { date: IMyDate };

    @Input() public endDate: { date: IMyDate };

   

    public periodValue: string = '';


    constructor(
        protected ui: UiService,
        protected eRef: ElementRef
    ) {
    }

    @HostListener('document:click', ['$event'])
    public clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            if (this.datePickerVisible) {
                this.toggleDatePicker();
            }
        }
    }

    public toggleDatePicker() {
        this.datePickerVisible = !this.datePickerVisible;

        if (this.datePickerVisible) {
            this.ui.showPageMask();
        } else {
            this.ui.hidePageMask();
            this.emitIntervalValue();
        }
    }

    protected updateDatePickerOptions(source: IMyDpOptions, update: IMyDpOptions): IMyDpOptions {
        let newOptions = JSON.parse(JSON.stringify(source));

        Object.keys(update).forEach(key => {
            newOptions[key] = update[key];
        });

        return newOptions;
    }


    ngOnInit() {
        this.updateDateRangePiker();
    }


    public onStartDateChanged(date) {
        setTimeout(() => {
            this.updateDateRangePiker();
        });
    }

    public onEndDateChanged(date) {
        setTimeout(() => {
            this.updateDateRangePiker();
        });
    }

    @Output()
    public dateRangeChanged: EventEmitter<IMyDateRange> = new EventEmitter<IMyDateRange>();


    public setDatePeriod(period: IDateInterval) {
        const startDate = moment(period.from, ConfigService.DB_DATE_FORMAT);
        const endDate = moment(period.to, ConfigService.DB_DATE_FORMAT);
        
        this.startDate = { date: { day: startDate.date(), month: startDate.month() + 1, year: startDate.year() } };
        this.endDate = { date: { day: endDate.date(), month: endDate.month() + 1, year: endDate.year() } };

        this.markDatesAsSelected();
        this.toggleDatesAvailability();
        this.formatDateValue();
    }

    private updateDateRangePiker() {
        this.formatDateValue();
        this.markDatesAsSelected();
        this.toggleDatesAvailability();
    }
    


    private emitIntervalValue() {
        const event: IMyDateRange = {
            start: this.startDate,
            end: this.endDate
        };

        this.dateRangeChanged.emit(event);
    }


    private formatDateValue() {
        this.periodValue =
            (this.startDate ? moment(`${this.startDate.date.month}/${this.startDate.date.day}/${this.startDate.date.year}`, this.MOMENT_DATE_FORMAT).format(this.dateFormat ? this.dateFormat : this.MY_DATE_FORMATE) : '...') + ' - ' +
            (this.endDate ? moment(`${this.endDate.date.month}/${this.endDate.date.day}/${this.endDate.date.year}`, this.MOMENT_DATE_FORMAT).format(this.dateFormat ? this.dateFormat : this.MY_DATE_FORMATE) : '...');
    }


    private markDatesAsSelected() {
        if (this.startDate === undefined || this.endDate === undefined)
            return;

        const startDateFormatted = `${this.startDate.date.month}/${this.startDate.date.day}/${this.startDate.date.year}`;
        const endDateFormatted = `${this.endDate.date.month}/${this.endDate.date.day}/${this.endDate.date.year}`;

        let selectedDays: IMyDate[] = [];

        for (var m = moment(startDateFormatted, this.MOMENT_DATE_FORMAT); m.diff(moment(endDateFormatted, this.MOMENT_DATE_FORMAT), 'days') <= 0; m.add(1, 'days')) {
            selectedDays.push({
                day: m.date(),
                month: m.month() + 1,
                year: m.year()
            });
        }

        this.startDatePickerOptions = this.updateDatePickerOptions(this.startDatePickerOptions, {
            markDates: <IMyMarkedDates[]>[{
                color: '#ff0000',
                dates: [].concat(selectedDays)
            }]
        });

        this.endDatePickerOptions = this.updateDatePickerOptions(this.endDatePickerOptions, {
            markDates: <IMyMarkedDates[]>[{
                color: '#ff0000',
                dates: [].concat(selectedDays)
            }]
        });
    }


    private toggleDatesAvailability() {
        this.startDatePickerOptions = this.updateDatePickerOptions(this.startDatePickerOptions, { disableSince: this.endDate.date });
        this.endDatePickerOptions = this.updateDatePickerOptions(this.endDatePickerOptions, { disableUntil: this.startDate.date });
    }
}