﻿import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject, BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators'

import { IBreadCrumbItem } from 'shared/models/ui/IBreadCrumbItem';
import { NgxUiLoaderService } from 'ngx-ui-loader';



@Injectable({ providedIn: 'root' })
export class UiService {

    private readonly MAIN_MENU_COLLAPSED_KEY = 'mainMenuIsCollapsed';

    private _dead: Subject<void> = new Subject<void>();

    public popups: { [id: string]: boolean } = {};

    protected _pageMaskVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public get pageMaskVisible() { return this._pageMaskVisible.asObservable(); }

    protected _showBackEndActionMask: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public get showBackEndActionMask() { return this._showBackEndActionMask.asObservable(); }

    protected _documentClickObservable: Observable<MouseEvent>;
    public get documentClickEvent() { return this._documentClickObservable; }

    public onPopupOpen: EventEmitter<string> = new EventEmitter<string>();
    public onPopupClose: EventEmitter<string> = new EventEmitter<string>();

    protected _mainMenuIsCollapsed: boolean = false;
    public get mainMenuIsCollapsed(): boolean { return this._mainMenuIsCollapsed; };

    protected _mainMenuToggled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public get mainMenuToggled() { return this._mainMenuToggled.asObservable(); }
    
    protected _breadCrumb: BehaviorSubject<IBreadCrumbItem[]> = new BehaviorSubject<IBreadCrumbItem[]>([]);
    public breadCrumb: Observable<IBreadCrumbItem[]> = this._breadCrumb.asObservable();

    constructor(
        protected router: Router,
        protected loader: NgxUiLoaderService
    ) {
        const mainMenuIsCollapsed = localStorage.getItem(this.MAIN_MENU_COLLAPSED_KEY) === 'true';

        this._mainMenuIsCollapsed = mainMenuIsCollapsed;
        this._mainMenuToggled.next(this._mainMenuIsCollapsed);

        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            takeUntil(this._dead)
        ).subscribe(() => this.stopBackendAction());

        if (document) {
            this._documentClickObservable = fromEvent<MouseEvent>(document, 'click');
        }

        this._showBackEndActionMask.subscribe(visibility => {
            if (this.loader.getLoaders()['default']) {
                if (visibility) {
                    try {
                        this.loader.startLoader('default');
                    } catch (err) { }
                } else {
                    setTimeout(() => {
                        try {
                            this.loader.stopLoader('default')
                        } catch (err) { }
                    }, 0);
                }
            }
        })
    }


    //#region PAGE MASKS

    public startBackendAction(): void {
        this._showBackEndActionMask.next(true);
    }


    public stopBackendAction(): void {
        this._showBackEndActionMask.next(false);
    }


    public showPageMask(): void {
        this._pageMaskVisible.next(true);
    }


    public hidePageMask(): void {
        this._pageMaskVisible.next(false);
    }

    //#endregion 


    //#region LAYOUT

    public mainMenuToggle() {
        this._mainMenuIsCollapsed = !this._mainMenuIsCollapsed;
        localStorage.setItem(this.MAIN_MENU_COLLAPSED_KEY, this._mainMenuIsCollapsed.toString());
        this._mainMenuToggled.next(this._mainMenuIsCollapsed);
    }

    public setHeaderTitle(title: string) {
        this._breadCrumb.next([{ title: title }]);
    }

    public setPageBreadcrumb(breadcrumb: IBreadCrumbItem[]) {
        setTimeout(() => {
            this._breadCrumb.next(breadcrumb);
        });
    }


    public addBodyClass(className: string) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add(className);
    }


    public removeBodyClass(className: string) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove(className);
    }


    public addHtmlClass(className: string) {
        const body = document.getElementsByTagName('html')[0];
        body.classList.add(className);
    }


    public removeHtmlClass(className: string) {
        const body = document.getElementsByTagName('html')[0];
        body.classList.remove(className);
    }
    //#endregion



    public destroy(): void {
        this._dead.next();
        this._dead.complete();
    }
}

