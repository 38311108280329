﻿import {Component, Input, EventEmitter, Output} from '@angular/core';
import { IApiOrdering } from 'shared/models/api/IApiOrdering';


@Component({
    selector: 'dt-sortable-header,[dt-sortable-header]',
    templateUrl: 'dt-sortable-header.component.html',
    providers: [],
})
export class DtSortableHeaderComponent {
    constructor() {}

    @Input()
    public order: IApiOrdering;

    @Output()
    public orderChange: EventEmitter<IApiOrdering> = new EventEmitter<IApiOrdering>();

    @Input()
    public name: string;


    public sort(sortDesc: boolean = false): void {
        if (
            this.order.sortBy === this.name &&
            this.order.sortDesc === sortDesc
        ) {
            return;
        }

        this.order.sortBy = this.name;
        this.order.sortDesc = sortDesc;

        this.orderChange.emit(this.order);
    }
}
