import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { TranslateModule } from "@ngx-translate/core";
import { NgxMaskModule } from 'ngx-mask';
import { MyDatePickerModule } from "mydatepicker";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { FormValidationService } from "shared/services/form-validation.service";
import { DictionariesService } from "shared/services/dictionaries.service";

import { BreadcrumbComponent } from "shared/components/layout/breadcrumb.component";
import { CollapsableAreaComponent } from "shared/components/layout/collapsable-area.component";

import { NotFoundPageComponent } from "shared/components/not-found-page/not-found-page.component";
import { DropdownComponent } from "shared/components/controls/dropdown/dropdown.component";
import { RadioGroupComponent } from "shared/components/controls/radio-group/radio-group.component";
import { ActivityChangerComponent } from "shared/components/controls/activity-changer/activity-changer.component";
import { CheckboxComponent } from "shared/components/controls/checkbox/checkbox.component";
import { TabsComponent } from "shared/components/controls/tabs/tabs.component";
import { FileLoaderCmponent } from "shared/components/controls/file-loader/file-loader.component";

import { DateRangePickerComponent } from "shared/components/forms/date-range-picker/date-range-picker.component";
import { HelpHintComponent } from "shared/components/forms/help-hint/help-hint.component";
import { InputTextComponent } from "shared/components/forms/input-text/input-text.component";
import { InputTextDateComponent } from "shared/components/forms/input-text-date/input-text-date.component";
import { InputTextMacrosesComponent } from "shared/components/forms/input-text-macroses/input-text-macroses.component";
import { LabelOptionalComponent } from "shared/components/forms/label-optional/label-optional.component";
import { FormValidationComponent } from "shared/components/forms/form-validation/form-validation.component";
import { FormFieldValidationComponent } from "shared/components/forms/form-field-validation/form-field-validation.component";

import { AutofocusDirective } from "shared/directives/autofocus.directive";
import { EqualValidator } from 'shared/directives/equal-validator.directive';
import { LastElementDirective } from "shared/directives/last-element.directive";
import { IgnoreOnEnterDirective } from "shared/directives/ignore-onenter.directive";

import { ByteConvertPipe } from "shared/pipes/byte-convert.pipe";
import { DateTimePipe } from "shared/pipes/date-time.pipe";
import { GroupByPipe } from "shared/pipes/group-by.pipe";
import { DecimalSuffixPipe } from "shared/pipes/decimal-suffix.pipe";

import { DTActionsComponent } from "shared/components/data-table/dt-actions.component";
import { DTActionComponent } from "shared/components/data-table/dt-action.component";
import { DTSearchComponent } from "shared/components/data-table/search/dt-search.component";
import { DTPagerComponent } from "shared/components/data-table/dt-pager.component";
import { DTNameCellComponent } from "shared/components/data-table/dt-name-cell.component";
import { DtSortableHeaderComponent } from "shared/components/data-table/dt-sortable-header.component";
import { PopupComponent } from "shared/components/ui/popup.component";
import { FormSubmitComponent } from "shared/components/form-submit/form-submit.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule, 
        NgxMaskModule.forRoot({}),
        TranslateModule.forChild(),
        MyDatePickerModule,
        DragDropModule
    ],
    declarations: [
        NotFoundPageComponent,
        BreadcrumbComponent, CollapsableAreaComponent, PopupComponent,
        DropdownComponent, RadioGroupComponent, ActivityChangerComponent, CheckboxComponent, TabsComponent,
        FormFieldValidationComponent, FormValidationComponent, FileLoaderCmponent,
        DateRangePickerComponent, HelpHintComponent, InputTextComponent, InputTextDateComponent, InputTextMacrosesComponent, LabelOptionalComponent,
        ByteConvertPipe, DateTimePipe, GroupByPipe, DecimalSuffixPipe,
        DTActionsComponent, DTActionComponent, DTSearchComponent, DTPagerComponent, DtSortableHeaderComponent, DTNameCellComponent,
        FormSubmitComponent,
        EqualValidator,
        AutofocusDirective, LastElementDirective, IgnoreOnEnterDirective
    ],
    exports: [
        NotFoundPageComponent,
        BreadcrumbComponent, CollapsableAreaComponent, PopupComponent,
        DropdownComponent, RadioGroupComponent, ActivityChangerComponent, CheckboxComponent, TabsComponent,
        FormFieldValidationComponent, FormValidationComponent, FileLoaderCmponent,
        DateRangePickerComponent, HelpHintComponent, InputTextComponent, InputTextDateComponent, InputTextMacrosesComponent, LabelOptionalComponent,
        ByteConvertPipe, DateTimePipe, GroupByPipe, DecimalSuffixPipe,
        DTActionsComponent, DTActionComponent, DTSearchComponent, DTPagerComponent, DtSortableHeaderComponent, DTNameCellComponent,
        FormSubmitComponent,
        EqualValidator,
        AutofocusDirective, LastElementDirective, IgnoreOnEnterDirective
    ],
    providers: [
        FormValidationService,
        DictionariesService
    ]
})
export class SharedModule {
}
