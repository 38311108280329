﻿import { Component, Input, Output, forwardRef, EventEmitter, ElementRef, IterableDiffers, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { UiService } from 'shared/services/ui.service';
import { IRadioGroupElement } from 'shared/models/ui/IRadioGroupElement';


@Component({
    selector: 'radio-group',
    templateUrl: 'radio-group.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroupComponent),
            multi: true
        }
    ]
})
export class RadioGroupComponent
    implements ControlValueAccessor, OnChanges {
    //
    // TODO
    // remove double emitting event
    // after clicking on items
    //


    private _selectedIndex?: number = null;
    public get selectedIndex(): number { return this._selectedIndex; }

    propagateChange = (_: any) => { };
    propagateTouched = (_: any) => { };


    //#region inputs and outputs
    @Input()
    public items: IRadioGroupElement[] = [];

    @Input()
    public readonly: boolean = false;

    @Input()
    public model: string;

    @Input()
    public vertical: boolean = false;

    @Output()
    public modelChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public stateChange: EventEmitter<IRadioGroupElement> = new EventEmitter<IRadioGroupElement>();

    @Input()
    public logging: boolean = false;

    //#endregion


    constructor() { }


    private log(...log: any[]) {
        if (!this.logging)
            return;

        console.log(...log);
    }


    //#region lifecycle hooks

    ngOnChanges(changes) {
        this.log('ngOnChanges: ', changes.model);

        if (changes.model && changes.model.previousValue != changes.model.currentValue) {
            this.log('ngOnChanges: ', changes.model.previousValue, changes.model.currentValue);

            const newValue: string = changes.model.currentValue as string;
            const index = this.items.findIndex(item => item.value == newValue);

            if (index > -1) {
                this._selectedIndex = index;
                this.emitModelValue(this.model);
                return;
            }
        }
    }
    //#endregion


    //#region ControlValueAccessor realization

    public writeValue(obj: any): void {
        this.log('writeValue: ', obj);

        if (obj === null) {
            this.model = null;
        } else if (obj != undefined && this.items.map(x => x.value).indexOf(obj.toString()) > -1) {
            const index = this.items.findIndex(item => item.value == obj);
            if (index > -1) {
                this._selectedIndex = index;
                this.model = obj.toString();
                this.modelChange.emit(this.model);
            } else {
                this.model = null;
            }
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.readonly = true;
    }

    //#endregion



    //#region private methods

    public get isDisabled(): boolean {
        return this.readonly || !this.items || this.items.length == 0;
    }


    private emitModelValue(value: string) {
        this.log('RadioGroupComponent: emitModelValue: ', value);
        this.modelChange.emit(value);
        this.propagateChange(value);
    }

    //#endregion



    //#region public methods

    public itemClick(index: number, event: any): void {
        if (!this.isDisabled) {
            this._selectedIndex = index;
            const newValue: string = this.items[index].value;

            this.log('RadioGroupComponent: itemClick: ', index, newValue);

            this.emitModelValue(newValue);
            this.model = newValue;
        }
    }

    //#endregion
}