﻿import { Component, Input, ChangeDetectionStrategy, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { IBreadCrumbItem } from 'shared/models/ui/IBreadCrumbItem';
import { UiService } from 'shared/services/ui.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent
    implements OnInit, OnDestroy
{
    private _items?: IBreadCrumbItem[] = [];
    public get items() { return this._items }
    public get visible() { return this._items.length > 0 }
    public get isSingleItem() { return this.items.length == 1 }

    private subscription: Subscription;

    constructor(
        private ui: UiService,
        private cdr: ChangeDetectorRef
    ) {
        
    }

    ngOnInit() {
        this.subscription = this.ui.breadCrumb.subscribe(bc => {
            if (bc) {
                this._items = [].concat(bc);
                this.cdr.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
