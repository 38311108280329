﻿import {Component, Input, forwardRef, Optional, Host, SkipSelf, OnInit, ElementRef, HostListener} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlContainer} from '@angular/forms';

import {TranslateService} from '@ngx-translate/core';

import {FormInputComponent} from '../formInputComponent';
import {IMacrosDescription} from './IMacrosDescription';


@Component({
    selector: 'input-text-macroses',
    templateUrl: 'input-text-macroses.component.html',
    styleUrls: ['input-text-macroses.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextMacrosesComponent),
            multi: true,
        },
    ],
})
export class InputTextMacrosesComponent
    extends FormInputComponent<string>
    implements OnInit {
    public get errors(): string[] {
        return this._errors;
    }

    public get opened(): boolean {
        return this._opened;
    }

    constructor(
        @Optional() @Host() @SkipSelf()
        protected controlContainer: ControlContainer,
        private eRef: ElementRef,
        private i18n: TranslateService,
    ) {
        super(controlContainer, null);
    }

    private _errors: string[] = [];

    private _opened = false;

    private _cursorPosition?: number;

    @Input()
    public macroses: IMacrosDescription[] =  [
        {name: '{{timestamp}}', description: this.i18n.instant('app.banners.macroses.timestamp')},
        {name: '{{advertiser_id}}', description: this.i18n.instant('app.banners.macroses.advertiser_id')},
        {name: '{{campaign_id}}', description: this.i18n.instant('app.banners.macroses.campaign_id')},
        {name: '{{campaign_name}}', description: this.i18n.instant('app.banners.macroses.campaign_name')},
        {name: '{{banner_id}}', description: this.i18n.instant('app.banners.macroses.banner_id')},
        {name: '{{banner_name}}', description: this.i18n.instant('app.banners.macroses.banner_name')},
        {name: '{{size}}', description: this.i18n.instant('app.banners.macroses.size')},
    ];

    @Input()
    public position: 'top' | 'bottom' = 'top';

    @Input() public type: 'text' | 'textarea' = 'text';

    @HostListener('document:click', ['$event'])
    clickout(event: any): void {
        if (!this.eRef.nativeElement.contains(event.target)) {
            if (this.opened) {
                this.close();
            }
        }
    }

    public toglleOpenedState(index?: number): void {
        this._opened = !this._opened;
    }

    public close(): void {
        this._opened = false;
    }

    public insertMacro(macroIndex: number): void {
        const macro = this.macroses[macroIndex].name;

        if (this._cursorPosition) {
            this._model = this._model.substr(0, this._cursorPosition) + macro + this._model.substr(this._cursorPosition);
            this._cursorPosition += macro.length;
        } else {
            this._model += macro;
        }

        this.modelChange.emit(this._model);
    }

    public onblur(itemIndex?: number, event?: any): void {
        const inputs = (this.eRef.nativeElement as HTMLElement).getElementsByTagName('input');
        if (inputs && inputs[0]) {
            this._cursorPosition = (inputs[0] as HTMLInputElement).selectionStart;
        }
    }
}
