﻿import { Input,  OnInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';


export class FormInputComponent<ModelType>
    implements OnInit
{
    protected _model: ModelType;
    @Input()
    public set model(value) {
        this._model = value;
        this.onChange(this._model);
        this.modelChange.emit(this._model);
        this.onTouched();
    }
    public get model() {
        return this._model;
    }

    @Output()
    public modelChange: EventEmitter<ModelType> = new EventEmitter<ModelType>();

    @Input()
    public readonly: boolean = false;

    public disabled: boolean = false;

    @Input()
    public htmlType: string = 'text';

    @Input()
    public formControlName: string;

    /*
    @Input()
    public placeholder: string;
    */

    @Input()
    public label: string = null;

    @Input()
    public inputMask: string = '';

    @Input()
    public inputMaskPrefix: string = '';

    @Input()
    public optional: boolean = false;

    public get noLabel() { return !this.label || this.label.length == 0 }

    protected control: AbstractControl;

    constructor(
        //@Optional() @Host() @SkipSelf()
        protected controlContainer: ControlContainer,
        protected cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        if (this.controlContainer) {
            if (this.formControlName) {
                this.control = this.controlContainer.control.get(this.formControlName);
            }
        }
    }

    //#region ControlValueAccessor realization

    writeValue(obj: any): void {
        if (obj === null || obj === undefined) {
            this._model = undefined;
        } else {
            this._model = obj.toString();
        }

        if (this.cdr) {
            this.cdr.markForCheck();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
    }

    onChange: any = () => { };

    onTouched: any = () => { };

    //#endregion
}
