﻿import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { IValidationMessage } from 'shared/models/forms/IValidationMessage';
import { FormValidationService } from 'shared/services/form-validation.service';


@Component({
    selector: 'form-field-validation',
    templateUrl: './form-field-validation.component.html'
})
export class FormFieldValidationComponent
    implements OnInit, OnDestroy
{
    private dead: Subject<void> = new Subject<void>();

    @Input()
    public fieldName: string = null;

    public messages: string[] = [];

    constructor(
        private validator: FormValidationService
    ) { }

    ngOnInit() {        
        this.validator.lastMessage
            .pipe(takeUntil(this.dead))
            .subscribe((message: IValidationMessage) => {
                this.messages = [];

                if (!message || !message.apiResponse || !message.apiResponse.errorDetails) {
                    return
                }

                const fieldErrors = message.apiResponse.errorDetails.filter(e => e.errorDescriptor.toLowerCase() == this.fieldName.toLowerCase());

                fieldErrors.forEach(error => {
                    this.messages = this.messages.concat(error.errorMessage);
                });
            });
    }

    ngOnDestroy() {
        this.dead.next();
        this.dead.complete();
    }
}