﻿import { Component, forwardRef, Optional, Host, SkipSelf, OnInit, HostListener, ElementRef, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { FormInputComponent } from '../formInputComponent';
import { IMyDpOptions, IMyDate, IMyDateModel } from 'mydatepicker';
import * as moment from 'moment';

@Component({
    selector: 'input-text-date',
    templateUrl: 'input-text-date.component.html',
    styleUrls: ['input-text-date.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextDateComponent),
            multi: true
        }
    ]
})

export class InputTextDateComponent
    extends FormInputComponent<string>
    implements OnInit, OnChanges
{
    public datePickerVisible = false;

    protected readonly MOMENT_DATE_FORMAT: string = 'M/D/YYYY';
    protected readonly MY_DATE_FORMATE: string = 'YYYY-MM-DD';

    public date: { date: IMyDate };

    public dpOptions: IMyDpOptions = {
        inline: true,
        showInputField: false,
        dateFormat: this.MY_DATE_FORMATE,
        satHighlight: true
    }


    @Input()
    public datePickerOptions: IMyDpOptions = {};

    constructor(
        @Optional() @Host() @SkipSelf()
        protected controlContainer: ControlContainer,
        protected eRef: ElementRef
    ) {
        super(controlContainer, null);
    }

    ngOnChanges(changes) {
        if (changes && changes.datePickerOptions && changes.datePickerOptions.currentValue) {
            this.dpOptions = this.updateDatePickerOptions(this.dpOptions, changes.datePickerOptions.currentValue);
        }
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.model) {
            const dateModel = moment(this.model);
            this.date = {
                date: {
                    day: dateModel.date(),
                    month: dateModel.month() + 1,
                    year: dateModel.year()
                }
            }
        }
    }

    public toggleDatePicker() {
        this.datePickerVisible = !this.datePickerVisible;
    }

    @HostListener('document:click', ['$event'])
    public clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            if (this.datePickerVisible) {
                this.toggleDatePicker();
            }
        }
    }

    writeValue(obj: any): void {
        if (obj === null || obj === undefined || obj === '') {
            this.model = undefined;
        } else {
            if (typeof obj == 'string') {
                this.model = moment(obj.toString()).format(this.MY_DATE_FORMATE);
            } else {
                this.model = moment(`${obj.date.month}/${obj.date.day}/${obj.date.year}`, this.MOMENT_DATE_FORMAT).format(this.MY_DATE_FORMATE);
            }
        }
    }

    public onDateChanged(date: IMyDateModel) {
        setTimeout(() => {
            this.writeValue(date);
            this.toggleDatePicker();
        });
    }

    protected updateDatePickerOptions(source: IMyDpOptions, update: IMyDpOptions): IMyDpOptions {
        let newOptions = JSON.parse(JSON.stringify(source));

        Object.keys(update).forEach(key => {
            newOptions[key] = update[key];
        });

        return newOptions;
    }
}
