import { Component } from '@angular/core';
import { AppTranslationService } from 'shared/services/app-translation.service';

@Component({
    selector: 'app-root',
    templateUrl: './app-root.component.html'
})
export class AppRootComponent {
    constructor(
        private translationService: AppTranslationService
    ) {
        this.translationService.addLanguages(["en", "ru"]);
        this.translationService.setDefaultLanguage(this.translationService.defaultLanguage);
        this.translationService.useBrowserLanguage();
    }
}
