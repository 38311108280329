﻿import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { ISystemMessage } from '../models/ui/ISystemMessage';


@Injectable()
export class SystemMessagesService {
    private dead: Subject<void> = new Subject<void>();

    private defaultSuccessMessage: string = 'THIS IS DEFAULT SUCCESS MESSAGE';

    private timer: any;

    constructor(
    ) {
    }
    

    private _lastMessage: BehaviorSubject<ISystemMessage> = new BehaviorSubject<ISystemMessage>(null);

    get lastMessage() {
        return this._lastMessage;
    }


    private setClearingTimeout() {
        this.timer = setTimeout(() => {
            this.clearMessage();
        }, 10000);
    }


    public setMessage(message: ISystemMessage): void {
        clearTimeout(this.timer);
        this._lastMessage.next(message);
        this.setClearingTimeout();
    }


    public infoMessage(message: string): void {
        this.setMessage({
            text: message,
            type: 'info',
            title: null
        });
    }


    public errorMessage(message: string): void {
        this.setMessage({
            text: message,
            type: 'error',
            title: null
        });
    }


    public successMessage(message?: string): void {
        this.setMessage({
            text: message ? message : this.defaultSuccessMessage,
            type: 'success',
            title: null
        });
    }


    public clearMessage(): void {
        clearTimeout(this.timer);
        this._lastMessage.next(null);
    }


    public destroy(): void {
        this.dead.next();
        this.dead.complete();
    }
}

