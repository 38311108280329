import { Injectable } from '@angular/core';
import { TranslateService, TranslateLoader } from '@ngx-translate/core';
import { Observable, Subject, of, BehaviorSubject } from 'rxjs';



@Injectable()
export class AppTranslationService {

    readonly defaultLanguage = "ru";
    private onLanguageChanged = new BehaviorSubject<string>(this.defaultLanguage);
    languageChanged$ = this.onLanguageChanged.asObservable();

    constructor(private translate: TranslateService) {
        this.setDefaultLanguage(this.defaultLanguage);
    }


    public addLanguages(lang: string[]) {
        this.translate.addLangs(lang);
    }


    public setDefaultLanguage(lang: string) {
        this.translate.setDefaultLang(lang);
    }

    public getDefaultLanguage() {
        return this.translate.defaultLang;
    }

    public getBrowserLanguage() {
        return this.translate.getBrowserLang();
    }

    public getCurrentLanguage() {
        return this.translate.currentLang;
    }


    public useBrowserLanguage(): string | void {
        let browserLang = this.getBrowserLanguage();

        if (browserLang.match(/en|ru/)) {
            this.changeLanguage(browserLang);
            return browserLang;
        }
    }

    public changeLanguage(language: string = "ru") {
        if (!language)
            language = this.translate.defaultLang;

        if (language != this.translate.currentLang) {
            setTimeout(() => {
                this.translate.use(language);
                this.onLanguageChanged.next(language);
            });
        }

        return language;
    }


    public getTranslation(key: string | Array<string>, interpolateParams?: Object): string | any {
        return this.translate.instant(key, interpolateParams);
    }


    public getTranslationAsync(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
        return this.translate.get(key, interpolateParams);
    }

}




export class TranslateLanguageLoader implements TranslateLoader {

    public getTranslation(lang: string): any {

        //Note Dynamic require(variable) will not work. Require is always at compile time
        switch (lang) {
            case "en":
                return of(require("../../assets/locale/en.json"));
            case "ru":
                return of(require("../../assets/locale/ru.json"));
            default:
        }
    }
}
