﻿import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'label-optional',
    templateUrl: 'label-optional.component.html'
})

export class LabelOptionalComponent
{
    constructor(
    ) {
    }
}