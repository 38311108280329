﻿import { Component, Input, EventEmitter, Output, forwardRef, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ITabItem } from 'shared/models/ui/ITabItem';


@Component({
    selector: 'tabs',
    templateUrl: 'tabs.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TabsComponent),
            multi: true
        }
    ]
})
export class TabsComponent
    implements OnChanges
{
    private _model?: string = null;
    @Input() set model(model: string) { this._model = model; }
    get model() { return this._model; }

    @Output() modelChange = new EventEmitter<string>();

    propagateChange = (_: any) => { };
    propagateTouched = (_: any) => { };

    private _tabs: ITabItem[] = [];
    @Input() public set tabs(tabs: ITabItem[]) { this._tabs = [].concat(tabs) };
    public get tabs() { return this._tabs }

    @Output() public tabsChange: EventEmitter<ITabItem[]> = new EventEmitter<ITabItem[]>();

    @Input() public disabled: boolean = false;


    constructor() { }

    ngOnChanges(changes: any) {
        if (changes.model && changes.model.currentValue) {
            this.tabs.forEach(tab => tab.selected = changes.model.currentValue == tab.value);
        }
    }

    private emitValue() {
        this.propagateChange(this._model);
        this.modelChange.emit(this._model);
        this.tabsChange.emit(this.tabs);
    }

    public select(index: number) {
        this.tabs.forEach((tab, i) => tab.selected = index == i);
        this._model = this.tabs[index].value;
        this.emitValue();
    }

    //#region ControlValueAccessor realization

    writeValue(obj: any): void {
        if (obj != undefined && !!obj === obj) {
            this._model = obj;
            this.emitValue();
        }
    }


    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    //#endregion
}