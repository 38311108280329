﻿import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'dt-action',
    templateUrl: 'dt-action.component.html',
})
export class DTActionComponent {
    constructor(
        private router: Router
    ) {
    }

    @Input()
    public iconClass: string = '';

    @Input()
    public link?: any;

    @Output()
    public clickEvent?: EventEmitter<any> = new EventEmitter<any>();

    public get onClickSubscribed() {
        return this.clickEvent.observers.length > 0;
    }

    public runClickEvent() {
        this.clickEvent.emit();
    }
}