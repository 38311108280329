﻿import { Injectable, TemplateRef } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { DtSearchEvent } from './SearchEvent';


@Injectable()
export class DtSearchService {
    private _filtersForm: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject(null);
    public get filtersFormLastValue() { return this._filtersForm.value }
    public get filtersForm() { return this._filtersForm.asObservable() }

    public filter: any;

    private _defaultFilterState: any;
    public get defaultFilterState() { return this._defaultFilterState }

    private _searchEvent: Subject<DtSearchEvent<any>> = new Subject();
    public get searchEvent() { return this._searchEvent.asObservable() }

    private _enabled: boolean = false;
    public get enabled() { return this._enabled }

    constructor(
    ) {
    }

    public search(event: DtSearchEvent<any>) {
        this._searchEvent.next(event);
    }

    // todo
    // try to make this typed
    public enable(form: TemplateRef<any>, filter: any, defaultFilterState: any) {
        this._filtersForm.next(form);
        this.filter = filter;
        this._defaultFilterState = defaultFilterState;
        this._enabled = true;
    }

    public disable() {
        this._enabled = false;
    }
}

