import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, OnDestroy, OnChanges } from '@angular/core';


@Component({
    selector: 'file-loader',
    templateUrl: './file-loader.component.html'
})
export class FileLoaderCmponent
    implements OnDestroy, OnChanges {
    @Input()
    public accept: string = "";

    @Output()
    public onLoad: EventEmitter<File> = new EventEmitter<File>();

    @ViewChild('fileOpenBtn')
    private fileOpenBtn: ElementRef;

    private _loadedFile: File = null;
    public get loadedFile() { return this._loadedFile };

    @Input()
    public file: File = null;

    @Input()
    public validationMessages: string[] = [];

    private _label: string = "";
    public get label() { return this._label }
    @Input() public set label(value: string) { this._label = value }

    private _error: string = "";
    public get error() { return this._error }


    constructor() { }


    private emit() {
        this.onLoad.emit(this._loadedFile);
    }

    public fireFileDialogClickEvent() {
        this.fileOpenBtn.nativeElement.click();
    }

    public onFilesLoadedByInput(event) {
        if (event.target && event.target.files && event.target.files.length > 0) {
            this._loadedFile = (event.target.files as FileList)[0];
            this.emit();
        }
    }

    public reset() {
        this._loadedFile = null;
        this.emit();
    }

    ngOnChanges(changes) {
        if (changes && changes.file) {
            this._loadedFile = changes.file.currentValue != null
                ? changes.file.currentValue
                : null;
        }
    }

    ngOnDestroy() {
        this._loadedFile = null;
    }
}
