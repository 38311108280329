import { Component, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'popup',
    templateUrl: 'popup.component.html',
    providers: []
})

export class PopupComponent
{
    constructor(private loader: NgxUiLoaderService) {}

    private _visible: boolean = false;
    get visible() { return this._visible }

    @Input()
    public showHeader = true; 

    @Input()
    public showFooter = true;

    @Input()
    set visible(visible: boolean) {
        this._visible = visible;
        if (visible) {
            this.loader.startLoader(this.loaderId);
        } else {
            this.loader.stopLoader(this.loaderId);
        }            
    }

    @Output()
    visibleChange = new EventEmitter<boolean>();

    @Input()
    public loaderId = 'default';


    @HostListener('document:keypress', ['$event'])
    public handleKeyboardEvent(event: KeyboardEvent) {
        if (this.visible && event.keyCode === 27) {
            this.close();
        }
    }


    public close(): void {
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }
}
