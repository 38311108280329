﻿import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from "rxjs";

import { takeUntil } from 'rxjs/operators';
import { IValidationMessage } from 'shared/models/forms/IValidationMessage';
import { IApiFieldError } from 'shared/models/api/IApiFieldError';
import { FormValidationService } from 'shared/services/form-validation.service';


@Component({
    selector: 'form-validation',
    templateUrl: './form-validation.component.html'
})
export class FormValidationComponent
    implements OnInit, OnDestroy
{
    private dead: Subject<void> = new Subject<void>();

    public visible: boolean = false;
    public message: string = '';
    public errorDetails: IApiFieldError[] = [];

    public get showMessage() {
        return this.errorDetails.length == 0;
    }

    public get showDetails() {
        return this.errorDetails.length > 0;
    }

    @Input() formId: string;

    constructor(
        private validator: FormValidationService
    ) { }

    ngOnInit() {
        this.validator.lastMessage
            .pipe(takeUntil(this.dead))
            .subscribe((message: IValidationMessage) => {
                const validResponse = (message != undefined && message.apiResponse != undefined) || false;
                const hasError = !message.apiResponse.isSuccessful;

                this.errorDetails = validResponse && hasError
                    ? message.apiResponse.errorDetails.filter(errDet => !errDet.errorDescriptor || errDet.errorDescriptor.length == 0)
                    : [];

                console.log(this.errorDetails);

                this.message = validResponse && hasError
                    ? message.apiResponse.errorMessage
                    : '';

                this.visible = this.message.length > 0 || this.errorDetails.length > 0;
            });
    }

    ngOnDestroy() {
        this.dead.next();
        this.dead.complete();
    }
}