﻿import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'help-hint',
    templateUrl: 'help-hint.component.html'
})

export class HelpHintComponent
    implements OnInit
{
    @Input()
    public text: string;

    @Input()
    public direction: 'left' | 'right' = 'right';

    constructor(
    ) {
    }

    ngOnInit() {
    }
}