﻿import { Injectable } from '@angular/core';
import { IDropdownItem } from 'shared/models/ui/IDropDownItem';


export class DictionariesService
{
    private readonly _currencies: { [key: string]: IDropdownItem[] } = {
        "ru": [
            { id: "1", name: "Рубль" },
            { id: "2", name: "Доллар США" },
            { id: "3", name: "Евро" },
            { id: "4", name: "Фунт стерлингов" }
        ],
        "en": [
            { id: "1", name: "Ruble" },
            { id: "2", name: "American dollar" },
            { id: "3", name: "Euro" },
            { id: "4", name: "Pound sterling" }
        ]
    };
    public get currencies() { return this._currencies }

    private readonly _languages: IDropdownItem[] = [
        { id: '1', name: 'English' },
        { id: '2', name: 'Русский' }
    ];
    public get languages() { return this._languages }

    constructor(
    ) {
    }
}