﻿import { Component, AfterViewInit, OnDestroy, ElementRef, KeyValueDiffer, KeyValueDiffers, DoCheck } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { UiService } from 'shared/services/ui.service';
import { DtSearchService } from './dt.search.service';
import { DtSearchEvent } from './SearchEvent';


@Component({
    selector: 'dt-search',
    templateUrl: 'dt-search.component.html',
})
export class DTSearchComponent
    implements AfterViewInit, OnDestroy, DoCheck
{
    public key: string;

    private _focused: boolean = false;
    public get focused() { return this._focused }

    private _opened: boolean = false;
    public get opened() { return this._opened }

    private subscriptions: Subscription[] = [];

    public filtersForm: any;

    public filter: any = {};

    private filterDiffer: KeyValueDiffer<string, any>;

    private _changed: boolean = false;
    public get changed() { return this._changed }

    private filtersExistingChecked = false;
    private filterHasNotOnlyKeyField = false;
    public get showFiltersButton() { return this.filterHasNotOnlyKeyField }

    constructor(
        protected dtSearch: DtSearchService,
        protected router: Router,
        protected ui: UiService,
        private element: ElementRef,
        private differs: KeyValueDiffers
    ) {
        this.filterDiffer = this.differs.find(this.filter).create();
    }

    ngAfterViewInit() {
        this.subscriptions.push(this.dtSearch.filtersForm.subscribe(
            form => setTimeout(
                () => {
                    this.filtersForm = form;
                    this.filter = this.dtSearch.filter;
                }, 0)
        ));

        this.subscriptions.push(this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.dtSearch.disable();
                this.resetState();
            }
        }));

        this.subscriptions.push(this.dtSearch.searchEvent.subscribe((event: DtSearchEvent<any>) => {
            this.filter = JSON.parse(JSON.stringify(event.filters));
            this.calculateChanged();
        }));


        this.subscriptions.push(this.ui.documentClickEvent.subscribe((event: MouseEvent) => {
            if (this._opened && !(this.element.nativeElement as HTMLElement).contains(event.target as HTMLElement))
                this._opened = false;
        }));
    }

    ngDoCheck(): void {
        if (!this.filter)
            return;

        const changes = this.filterDiffer.diff(this.filter);
        if (changes) {
            
            if (!this.filtersExistingChecked) {
                this.filterHasNotOnlyKeyField = Object.getOwnPropertyNames(this.filter).length > 1;
                this.filtersExistingChecked = true;
            }

            this.calculateChanged();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private calculateChanged() {
        this._changed = JSON.stringify(this.filter) != JSON.stringify(this.dtSearch.defaultFilterState);
    }

    private resetState() {
        this.key = '';
        this._opened = false;
        this._focused = null;
        this.filtersForm = null;
    }

    public onfocus() {
        this._focused = true;
    }

    public onblur() {
        this._focused = false;
    }

    public toggleOpenState() {
        this._opened = !this._opened;
    }

    public search() {
        this._opened = false;
        this.emitSearchEvent();
    }

    public emitSearchEvent() {
        let filter: any = {};

        Object.getOwnPropertyNames(this.filter).forEach(propName => filter[propName] = this.filter[propName]);

        filter['key'] = this.key;

        this.dtSearch.search(new DtSearchEvent(filter));
    }

    public reset() {
        this.filter = JSON.parse(JSON.stringify(this.dtSearch.defaultFilterState));
        this.key = '';
        this._opened = false;
        this.emitSearchEvent();
    }
}