import { Directive, Input, Output, EventEmitter, ElementRef } from '@angular/core';


@Directive({
    selector: '[ignoreOnEnter]'
})
export class IgnoreOnEnterDirective {
    constructor(public elementRef: ElementRef) {
        (elementRef.nativeElement as HTMLElement).addEventListener('keypress',
            (event: KeyboardEvent) => {
                if (event.keyCode == 13) {
                    event.preventDefault();
                }
            }
        )
    }
}
