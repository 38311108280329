import { ModuleWithProviders } from "@angular/compiler/src/core";
import { NgModule, Optional, SkipSelf } from "@angular/core";

import { AppTranslationService } from "shared/services/app-translation.service";
import { SystemMessagesService } from "shared/services/system-messages.service";
import { MessageBusService } from "shared/services/messageBus/message-bus.service";
import { AppStateService } from "shared/services/app-state.service";
import { AuthService } from "shared/services/auth.service";
import { UiService } from "shared/services/ui.service";

import { DtSearchService } from "shared/components/data-table/search/dt.search.service";


@NgModule({
    imports: [],
    declarations: [],
    providers: []
})
export class SharedSingletonsModule {
    constructor(@Optional() @SkipSelf() parentModule: SharedSingletonsModule) {
        if (parentModule) {
            throw new Error(
                'SharedSingletonsModule is already loaded. Import it in the RootModule only');
        }
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedSingletonsModule,
            providers: [
                AuthService,
                UiService,
                AppTranslationService,
                MessageBusService,
                DtSearchService,
                SystemMessagesService,
                AppStateService
            ]
        };
    }
}
